const getUnregisterList = `${API_CONFIG.butlerBaseURL}user/information/unregister/list`
const exportUrl = `${API_CONFIG.butlerBaseURL}user/information/unregister/list/export`
const uploadUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/importButlerUser`
const unregisterDelete = `${API_CONFIG.butlerBaseURL}user/information/unregister/list/delete`
const importButlerOwnerAssetUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/importButlerOwnerAsset`

export {
  unregisterDelete,
  getUnregisterList,
  exportUrl,
  uploadUrl,
  importButlerOwnerAssetUrl
}
