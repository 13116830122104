var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.importUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _vm._v("\n     数据类型为“用户导入”\n     "),
            _c("div", [
              _c("p", [_vm._v("项目名称:必须是项目信息表中已有的项目名称;")]),
              _c("p", [_vm._v("苑:非必填;")]),
              _c("p", [_vm._v("幢:必填,例:1幢;")]),
              _c("p", [_vm._v("单元:非必填,例:2单元;")]),
              _c("p", [_vm._v("室:必填,例:101室;")]),
              _c("p", [_vm._v("姓名:必填,2-10位;")]),
              _c("p", [_vm._v("手机号:必填,11位手机号;")]),
              _c("p", [
                _vm._v("身份:必填,可选业主、家属、租客、伙伴、嘉宾中一项;"),
              ]),
              _c("p", [_vm._v("性别:必填,男或女;")]),
              _c("p", [_vm._v("身份证号:非必填,18位身份证号;")]),
              _c("p", [_vm._v("出生日期:非必填,例:2017-10-19;")]),
              _c("p", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }