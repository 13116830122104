<template>
  <div class="import-container">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="importUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
       数据类型为“用户导入”
       <div>
          <p>项目名称:必须是项目信息表中已有的项目名称;</p>
          <p>苑:非必填;</p>
          <p>幢:必填,例:1幢;</p>
          <p>单元:非必填,例:2单元;</p>
          <p>室:必填,例:101室;</p>
          <p>姓名:必填,2-10位;</p>
          <p>手机号:必填,11位手机号;</p>
          <p>身份:必填,可选业主、家属、租客、伙伴、嘉宾中一项;</p>
          <p>性别:必填,男或女;</p>
          <p>身份证号:非必填,18位身份证号;</p>
          <p>出生日期:非必填,例:2017-10-19;</p>
          <p>最大支持10MB的excel文件，超过请拆分导入</p>
       </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import {importButlerOwnerAssetUrl, uploadUrl} from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      importButlerOwnerAssetUrl,
      downloadUrl: 'static/excel/物业导入模板.xls',
      importUrl: ''
    }
  },
  created () {
      let { type } = this.$route.query
      if (type === 1) {
          this.$setBreadcrumb('既存业主房号导入')
          this.importUrl = this.importButlerOwnerAssetUrl
      } else {
          this.$setBreadcrumb('导入')
          this.importUrl = this.uploadUrl
      }
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .import-container {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
